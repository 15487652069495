:root {
  --primary-color: #30ba36;
  --secondary-color: #0d304f;
  --third-color: #949499;
  --light-black: #303030;
  --white-color: #ffffff;
  --black-color: #000000;
  --spacing-between-div: 2.8rem;
  --fz-paragraph: 1.8rem;
  --fz-h1: 6.8rem;
  --fz-xs-h1: 5.2rem;
  --fz-h2: 4.2rem;
  --fz-h3: 3.6rem;
  --fz-h4: 3rem;
  --fz-h5: 2.6rem;
  --fz-h6: 2.3rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.8rem;
  min-width: 350px;
  font-family: "Geogrotesque_Rg", Arial, Helvetica, sans-serif;
}

p {
  margin-bottom: 1.4rem;
  line-height: 2.6rem;
}

h1 {
  font-size: var(--fz-xs-h1);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

h2 {
  font-size: var(--fz-h2);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

h3 {
  font-size: var(--fz-h3);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

h4 {
  font-size: var(--fz-h4);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

h5 {
  font-size: var(--fz-h5);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

h6 {
  font-size: var(--fz-h6);
  margin-bottom: 1.6rem;
  font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.grid-6 {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-between-div);
}

.py-8 {
  padding: 8.6rem 0;
}

.text-center {
  text-align: center;
}

.btn-primary {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.428571429;
  margin-bottom: 0;
  background: var(--primary-color);
  color: var(--black-color);
  border-radius: 25px;
  padding: 1rem 2rem;
  user-select: none;
  white-space: nowrap;
}

.btn-secondary {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.428571429;
  margin-bottom: 0;
  background: var(--secondary-color);
  border-radius: 25px;
  padding: 1rem 2rem;
  user-select: none;
  white-space: nowrap;
}

.hidden-xs {
  display: none;
}

/*Top Bar*/
.top-bar-section {
  padding: 1.8rem;
  background-color: var(--light-black);
}
.top-bar-section .top-bar-section__list {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.top-bar-section .top-bar-section__list li {
  margin-left: 1.8rem;
  color: var(--white-color);
}
.top-bar-section .top-bar-section__list li a {
  color: var(--white-color);
  text-decoration: none;
}

/*Header Styles*/
.above-the-fold {
  background-color: var(--secondary-color);
  padding-bottom: 3.8rem;
}
.above-the-fold .header-section {
  padding: 1.8rem;
  background: linear-gradient(0deg, transparent 0%, var(--secondary-color) 65%) !important;
}
.above-the-fold .header-section .header-section__grid figure {
  text-align: center;
}
.above-the-fold .header-section .header-section__grid .menu-movil {
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 1.8rem 1.8rem;
  text-decoration: none;
  max-width: 64px;
  margin: 0 auto;
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
  display: flex;
  justify-content: center;
  align-content: center;
}
.above-the-fold .header-section .header-section__grid .menu-movil i {
  color: var(--white-color);
  font-size: var(--fz-h5);
}
.above-the-fold .header-section .header-section__grid nav > ul {
  display: none;
  background-color: var(--secondary-color);
  list-style: none;
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
}
.above-the-fold .header-section .header-section__grid nav > ul li {
  border-bottom: 1px solid var(--third-color);
  padding: 1.8rem 2.2rem;
}
.above-the-fold .header-section .header-section__grid nav > ul li a {
  text-decoration: none;
  color: var(--white-color);
}
.above-the-fold .header-section .header-section__grid nav > ul li a:hover {
  color: var(--primary-color);
}
.above-the-fold .header-section .header-section__grid nav > ul li ul {
  display: none;
  opacity: 1;
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li {
  list-style: none;
  line-height: 3.6rem;
  border-bottom: 1px solid var(--secondary-color-hover);
  padding: 0.8rem 0rem;
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li a {
  font-size: 1.6rem;
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li a:hover {
  color: var(--primary-color);
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li:hover {
  background: var(--secondary-color-hover);
}
.above-the-fold .header-section .header-section__grid nav > ul li:hover ul {
  background: var(--secondary-color);
  display: block;
  margin-top: 2.1rem;
  width: auto;
  height: auto;
  z-index: 10000;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li a:hover,
.above-the-fold .header-section .header-section__grid nav > ul li ul:not(:hover) li.active a {
  color: var(--primary-color);
}
.above-the-fold .header-section .header-section__grid nav > ul li:last-child {
  border-bottom: none;
}
.above-the-fold .header-section .header-section__grid nav > ul li ul li:last-child {
  border-bottom: none;
}
.above-the-fold .header-section .header-section__grid nav > .header-display-block {
  display: block;
  opacity: 1 !important;
  height: auto !important;
  transition: all 0.5s;
}

@media (min-width: 576px) {
  h1 {
    font-size: var(--fz-h1);
    margin-bottom: 1.6rem;
    font-family: "Geogrotesque_SmBd", Arial, Helvetica, sans-serif;
  }

  .container {
    max-width: 540px;
  }

  .grid-6 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: var(--spacing-between-div);
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .grid-6 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: var(--spacing-between-div);
  }

  .hidden-xs {
    display: block;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .grid-6 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--spacing-between-div);
  }

  .above-the-fold--overlay-color {
    clip-path: ellipse(100% 55% at 48% 44%);
  }

  .above-the-fold {
    clip-path: ellipse(100% 55% at 48% 44%);
  }
  .above-the-fold .header-section .header-section__grid {
    display: flex;
    align-items: center;
  }
  .above-the-fold .header-section .header-section__grid figure {
    flex-basis: 20%;
  }
  .above-the-fold .header-section .header-section__grid .menu-movil {
    display: none;
  }
  .above-the-fold .header-section .header-section__grid nav > {
    display: flex;
    justify-content: flex-end;
    flex-basis: 80%;
  }
  .above-the-fold .header-section .header-section__grid nav > ul {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    padding-right: 5rem;
    position: absolute;
    right: none;
    width: auto;
  }
  .above-the-fold .header-section .header-section__grid nav > ul li {
    border-bottom: none;
    padding: 0rem 1.1rem;
  }
  .above-the-fold .header-section .header-section__grid nav > ul li a {
    color: var(--white-color);
    padding: 0.4rem;
  }
  .above-the-fold .header-section .header-section__grid nav > ul li ul {
    display: none;
  }
  .above-the-fold .header-section .header-section__grid nav > ul li ul li a {
    font-size: 1.6rem;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  }
  .above-the-fold .header-section .header-section__grid nav > ul li ul li:hover {
    background: var(--primary-color);
  }
  .above-the-fold .header-section .header-section__grid nav > ul li ul li:hover a {
    color: var(--black-color);
  }
  .above-the-fold .header-section .header-section__grid nav > ul li:hover ul {
    background: var(--secondary-color);
    display: block;
    position: absolute;
    margin-top: 2.1rem;
    width: auto;
    height: auto;
    z-index: 10000;
    border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
  .above-the-fold .header-section .header-section__grid nav > .header-display-block {
    opacity: 1 !important;
    height: auto !important;
    transition: all 0.5s;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .above-the-fold .header-section .header-section__grid nav > ul li a {
    padding: 1.8rem;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}